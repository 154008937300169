/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

/**
 * A functional component that renders the application header with a logo, language dropdown, and translation functionality.
 *
 * @return {JSX.Element} The JSX element representing the application header.
 */

function Header() {
  const { t, i18n } = useTranslation()
  const logo: string = require("../Asset/icons/logo.svg").default
  const language: string = require("../Asset/icons/language.svg").default
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleChange = (lng: string) => {
    i18n.changeLanguage(lng)
    setIsDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.id !== "dropdownDefaultButton"
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div>
      <span className="fixed top-0 left-0 right-0 text-center text-md lg:text-3xl lg:top-5 md:text-2xl md:top-5 sm:text-xl sm:top-5 xs:text-lg xs:top-5 font-bold text-orange-500 pt-2">
        {t("faceVerification")}
      </span>
      <div className="absolute top-3 left-4 lg:h-20 w-20 lg:left-10 md:h-18 md-left-10 w-18 sm:h-15 sm:left-10 xs:h-12 xs:left-2 lg:m-1 lg:pt-1 flex flex-1 ">
        <img src={logo} alt="logo" className="h-12 w-12" />
      </div>

      <div className="absolute top-5 right-6 h-16 lg:m-1 lg:pt-1 lg:right-14 md:right-10 sm:right-6 flex flex-1 justify-end">
        <div>
           {/* <button
            id="dropdownDefaultButton"
            className="px-2 dropdown-trigger cursor-default"
            type="button"
            onClick={toggleDropdown}
          >
            <img src={language} alt="language" className="h-8 w-8" />
          </button> */}

          {isDropdownOpen && (
            <div
              id="dropdown"
              className="z-10 divide-y rounded-lg shadow w-44 absolute right-0 top-12"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {i18n.language === "en" && (
                  <li>
                    <div
                      onClick={() => handleChange("ja")}
                      id="lang_jp"
                      className="py-2 px-5 text-left block hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-black bg-white text-black rounded my-1 mx-2 cursor-default"
                    >
                      日本語
                    </div>
                  </li>
                )}
                {i18n.language === "ja" && (
                  <li>
                    <div
                      onClick={() => handleChange("en")}
                      id="lang_en"
                      className="py-2 px-5 text-left block hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-black bg-white text-black rounded my-1 mx-2 cursor-default"
                    >
                      English
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
